import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BooleanInput } from '@angular/cdk/coercion';
import { from, Subject, takeUntil } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { CoreServiceWorkerService } from 'app/core/serviceWorker/service-worker.service';
import { IAuthResponse } from 'app/shared/interfaces/auth_response';
import { AuthService } from 'app/core/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static readonly ngAcceptInputType_showAvatar: BooleanInput;

    @Input() showAvatar: boolean = false;
    user: any;
    public promptEvent;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _authService: AuthService,
        private _router: Router,
        private _coreServiceWorkerService: CoreServiceWorkerService
    ) { }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.loadUser();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    loadUser(): void {
        this._userService.userProfile$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((userProfile: IAuthResponse) => {
                const user = {
                    avatar: 'assets/images/avatars/person.png',
                    email: userProfile?.patient?.email,
                    name: `${userProfile?.patient?.firstName} ${userProfile?.patient?.lastName}`,
                    id: 'id',
                    phone: userProfile?.patient?.username,
                };
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

    download(): void {
        this._coreServiceWorkerService.installPWA();
    }

    async signOut(): Promise<void> {
        from(this._authService.logout()).subscribe({
            next: (success) => {
                if (success === true) {
                    this._router.navigate(['/']).then(() => {
                        window.location.reload();
                    });
                }
            },
        });
    }

}
