import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from './core/user/user.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CoreServiceWorkerService } from './core/serviceWorker/service-worker.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private readonly _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private readonly _userService: UserService,
        private readonly _router: Router,
        private readonly _coreSW: CoreServiceWorkerService,
    ) { }

    ngOnInit(): void {
        this.handleRedirection();
        this.checkForUpdate();
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    handleRedirection(): void {
        this._userService.getRedirectUrl()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((url) => {
                this._router.navigateByUrl(url);
            });
    }

    checkForUpdate(): void {
        this._coreSW.checkForUpdate()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((evt) => {
                if (evt?.type === 'VERSION_READY') {
                    document.location.reload();
                }
            });
    }
}
