import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { Observable, forkJoin, map, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MedecinGuard  {
    constructor(private _userService: UserService, private _router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return forkJoin([
            this._userService.chefIME$
            .pipe(take(1), map(profile => !!profile)),

            this._userService.medecin$
            .pipe(take(1), map(profile => !!profile)),
        ])
        .pipe(
            map((data) => {
                const haveAccess = data.includes(true);
                if (!haveAccess) {
                    this._router.navigateByUrl('/app/dashboard');
                }
                return haveAccess;
            }),
        );
    }

}
