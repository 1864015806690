import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/services/auth.service';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SignInComponent implements OnInit, OnDestroy {

    @ViewChild('signInNgForm') signInNgForm: NgForm;
    hide = true;
    blockDuration: number = 5 * 60 * 1000;
    remainingTime: number = 0;
    timerInterval: any;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'chargement...'
    };
    signInForm: FormGroup;
    showAlert: boolean = false;
    realm: string = environment.keycloakConfig.realm;

    constructor(
        private readonly _authService: AuthService,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _userService: UserService,
    ) { }

    ngOnInit(): void {
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            rememberMe: [false],
        });
    }

    ngOnDestroy(): void {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    startTimer(): void {
        this.remainingTime = Math.ceil(parseInt(localStorage.getItem('blockUntil')) - new Date().getTime()) / 1000;
        this.timerInterval = setInterval(() => {
            this.remainingTime--;

            if (this.remainingTime <= 0) {
                clearInterval(this.timerInterval);
                this.remainingTime = 0;
                localStorage.removeItem('blockUntil');
            } else {
                this.alert.message = `Accès bloqué. Réessayez dans ${this.remainingTime} secondes.`;
            }
        }, 1000);
    }

    async signIn(): Promise<void> {
        const blockUntil = localStorage.getItem('blockUntil');
        if (blockUntil && new Date().getTime() < parseInt(blockUntil)) {
            this.startTimer();
            this.alert = {
                type: 'error',
                message: `Accès bloqué. Réessayez dans ${this.remainingTime} secondes.`
            };
            this.showAlert = true;
            return;
        }

        if (this.signInForm.valid) {
            this.signInForm.disable();
            const email = this.signInForm.value.email;
            const password = this.signInForm.value.password;
            let attemptCount = parseInt(localStorage.getItem('attemptCount')) || 0;

            try {
                const response = await this._authService.signIn(email, password);
                if (response && localStorage.getItem('authToken')) {
                    this._userService.get().subscribe();
                    this._userService.getRedirectUrl().subscribe();
                    localStorage.removeItem('attemptCount');
                }

            } catch (error) {
                this.signInForm.enable();
                attemptCount++;
                localStorage.setItem('attemptCount', attemptCount.toString());

                if (attemptCount >= 5) {
                    const blockUntilTime = new Date().getTime() + this.blockDuration;
                    localStorage.setItem('blockUntil', blockUntilTime.toString());
                    this.alert = {
                        type: 'error',
                        message: 'Accès bloqué pour plusieurs tentatives échouées. Réessayez plus tard.'
                    };
                } else {
                    this.alert = {
                        type: 'error',
                        message: 'Utilisateur ou mot de passe incorrect'
                    };
                }
                this.showAlert = true;
            }
        }
    }
}
