import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, take, catchError, throwError, from } from 'rxjs'; // Import 'from' pour convertir les Promises en Observables
import { UserService } from '../user/user.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class TokenInfoInterceptor implements HttpInterceptor {
    constructor(
        private _userService: UserService,
        private authService: AuthService,
        private _router: Router
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this._userService.headerInfo$.pipe(
            take(1),
            switchMap((data) => {
                const token = this.authService.getToken();

                const modifiedRequest = token
                ? request.clone({
                    headers: request.headers.set('jeton', token).set('TokenOthersInfo', JSON.stringify(data || {})),
                })
                : request;

                return next.handle(modifiedRequest).pipe(
                    catchError(error => this._handleAuthError(error, modifiedRequest, next))
                );
            })
        );
    }

    private _handleAuthError(err: any, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (err.status === 401) {
            return from(this.authService.refreshToken()).pipe(
                switchMap(() => {
                    const newToken = this.authService.getToken();
                    const newRequest = req.clone({
                        headers: req.headers.set('jeton', newToken),
                    });
                    return next.handle(newRequest);
                }),
                catchError(() =>
                    from(this.authService.logout()).pipe(
                        switchMap((success) => {
                            if (success === true) {
                                this._router.navigate(['/']).then(() => {
                                    window.location.reload();
                                });
                            }
                            return throwError(() => new Error('***** LOGOUT FAILED *****'));
                        })
                    )
                )
            );
        } else {
            return throwError(() => new Error());
        }
    }
}
