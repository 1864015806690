import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseCardModule } from '@fuse/components/card';
import { SharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SignInComponent } from 'app/modules/auth/sign-in/sign-in.component';
import { authSignInRoutes } from 'app/modules/auth/sign-in/sign-in.routing';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [
        SignInComponent,
    ],
    imports  : [
        HttpClientModule,
        RouterModule.forChild(authSignInRoutes),


        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FuseCardModule,
        FuseAlertModule,
        SharedModule
    ],
    providers: [
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: initializer,
        //   multi: true,
        //   deps: [KeycloakService, FuseConfirmationService]
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ErrorInterceptor,
        //     multi: true,
        // },
    ],
    exports  : [
        SignInComponent,
    ],
})
export class AuthModule {}
