import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { Observable, map, take } from 'rxjs';

@Injectable({
      providedIn: 'root'
})
export class SchoolGuard  {
    constructor(private _userService: UserService, private _router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._userService.chefAcademy$
        .pipe(
            take(1),
            map((data) => {
                const haveAccess = !!data;
                if (!haveAccess) {
                    this._router.navigateByUrl('/app/dashboard');
                }
                return haveAccess;
            }),
        );
    }

}
