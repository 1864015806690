import { FuseNavigationItem } from '@fuse/components/navigation';

export const chefEtabNavigation: FuseNavigationItem[] = [


    {
        id      : 'dashboard',
        title   : 'Etablissement',
        subtitle: 'Gestion de l\'établissement',
        type    : 'group',
        icon    : 'heroicons_outline:chart-pie',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'dashboard.home',
                title   : 'Accueil',
                type    : 'basic',
                icon    : 'heroicons_outline:home',
                link : '/app/dashboard/medecin',
            },
            {
                id      : 'dashboard.services',
                title   : 'Services',
                type    : 'basic',
                icon    : 'mat_outline:medical_services',
                link: '/app/etablissement/service'
            },
            {
                id      : 'dashboard.practitioner',
                title   : 'Personnels',
                type    : 'basic',
                icon    : 'mat_outline:manage_accounts',
                link: '/app/etablissement/practitioner'
            },
            {
                id      : 'dashboard.encounter',
                title   : 'Consultation',
                type    : 'basic',
                icon    : 'heroicons_outline:calendar',
                link: '/app/etablissement/encounter'
            },
        ]
    },
    {
        id      : 'medecin',
        title   : 'Médecin',
        subtitle: 'Mon espace medecin',
        type    : 'group',
        icon    : 'heroicons_outline:chart-pie',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'medecin.reservation',
                title   : 'Mes rendez-vous',
                type    : 'basic',
                icon    : 'mat_outline:access_time',
                link : '/app/medecin/reservation',
            },
            {
                id      : 'dashboard.encounter',
                title   : 'Mes consultations',
                type    : 'basic',
                icon    : 'mat_outline:date_range',
                link : '/app/medecin/encounter',
            },
            {
                id      : 'medecin.patient',
                title   : 'Mes patients',
                type    : 'basic',
                icon    : 'heroicons_outline:user-group',
                link: '/app/medecin/patient'
            },
            {
                id      : 'medecin.suivi',
                title   : 'Suivis',
                type    : 'basic',
                icon    : 'heroicons_outline:paper-clip',
                link: '/app/medecin/suivi'
            },
            {
                id      : 'dashboard.metadata',
                title   : 'Cachet & Signature',
                type    : 'basic',
                icon    : 'heroicons_outline:camera',
                link: '/app/medecin/metadata'
            },
            {
                id      : 'medecin.agenda',
                title   : 'Planifier mon agenda',
                type    : 'basic',
                icon    : 'mat_outline:date_range',
                link: '/app/medecin/agenda'
            },
        ]
    },
    {
        id      : 'tarification',
        title   : 'Tarification',
        subtitle: 'Gestion des paiements',
        type    : 'group',
        icon    : 'heroicons_outline:chart-pie',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'tarification.tarification',
                title   : 'Tarification',
                type    : 'basic',
                icon    : 'heroicons_outline:currency-euro',
                link: '/app/payement/tarification'
            },
            {
                id      : 'tarification.filtre',
                title   : 'Dashboard',
                type    : 'basic',
                icon    : 'heroicons_outline:newspaper',
                link: '/app/payement/dashboard'
            },
        ]
    },
    {
        id      : 'configuration',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:cog',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'configuration.module',
                title   : 'Compte',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link: '/app/setting'
            }

        ]
    },


];
